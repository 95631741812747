import React from "react"

import { graphql } from "gatsby"

import LayoutCustom from "../components/LayoutCustom"
import SEO from "../components/seo"

import {
  selectHalloweenPageContent,
  selectTranslations,
} from "../graphql/selectors"

import "./halloween.scss"

export default function HalloweenPage(props) {
  const { data, pageContext } = props

  const { langCode, localePath, pagePathsByLangCode } = pageContext

  const translations = selectTranslations(data)

  const {
    seo,
    iconAlt,
    cheeseTextAlt,
    cheeseImgAlt,
    ketchupTextAlt,
    ketchupImgAlt,
    onionTextAlt,
    onionImgAlt,
    tomatoTextAlt,
    tomatoImgAlt,
    baconTextAlt,
    baconImgAlt,
    iconUrl,
    ctaUrl,
    heroLogoImg,
    heroIntroText,
    heroCtaText,
    heroCtaArrow,
    heroArrowImg,
    leadHeading1,
    leadText1,
    leadHeading2,
    leadText2,
    leadHeading3,
    leadText3,
    quoteText1,
    quoteImg1,
    quotePerson1,
    bigYellowArrow,
    cheeseText,
    smallText,
    cheeseHeader,
    cheeseImg,
    cheeseDesc,
    cheeseQuote,
    smallYellowArrow,
    cheeseQuotePerson,
    solutionText1,
    solutionText2,
    ketchupText,
    ketchupHeader,
    ketchupImg,
    ketchupDesc,
    ketchupQuote,
    smallRedArrow,
    ketchupQuotePerson,
    onionText,
    onionHeader,
    onionImg,
    onionDesc,
    onionQuote,
    smallGreenArrow,
    onionQuotePerson,
    tomatoText,
    tomatoHeader,
    tomatoImg,
    tomatoDesc,
    tomatoQuote,
    tomatoQuotePerson,
    baconText,
    baconHeader,
    baconImg,
    baconDesc,
    baconQuote,
    baconQuotePerson,
  } = selectHalloweenPageContent(data)

  return (
    <LayoutCustom i18n={{ ...translations, localePath }} langCode={langCode}>
      <SEO {...seo} lang={langCode} />

      <div id="bk-content">
        <div id="bk-header">
          <div className="bk-box">
            <a aria-label="Homepage" href={iconUrl}>
              <img alt={iconAlt} className="logo" src={heroLogoImg} />
            </a>
            <div className="bk-box-header-right">
              <h1>{heroIntroText}</h1>
              <a aria-label="Order" className="bk-CTA" href={ctaUrl}>
                {heroCtaText} <img alt="" src={heroCtaArrow} />
              </a>
            </div>
          </div>
        </div>
        <div id="bk-intro">
          <div className="bk-box-img">
            <img alt="" src={heroArrowImg} />
          </div>
          <div className="bk-box">
            <div className="bk-box-single">
              <h2>{leadHeading1}</h2>
              <p dangerouslySetInnerHTML={{ __html: leadText1 }}></p>
            </div>
            <div className="bk-box-single">
              <h2>{leadHeading2}</h2>
              <p>{leadText2}</p>
            </div>
            <div className="bk-box-single">
              <h2>{leadHeading3}</h2>
              <p dangerouslySetInnerHTML={{ __html: leadText3 }}></p>
            </div>
            <div className="bk-box-single">
              <div className="bk-comment">
                <div className="bk-comment-wrap">
                  <p>{quoteText1}</p>
                </div>
                <img alt="" src={quoteImg1} />
                <h2>{quotePerson1}</h2>
              </div>
            </div>
          </div>
        </div>
        <div id="bk-main">
          <div className="bk-box-img">
            <img alt="" src={bigYellowArrow} />
          </div>
          <div className="bk-box cheeseContent">
            <img alt={cheeseTextAlt} src={cheeseText} />
            <div className="tiny-header">
              <span className="small">{smallText}</span>
              <span className="big">{cheeseHeader}</span>
            </div>
            <div className="bk-box-single">
              <img alt={cheeseImgAlt} className="spooky" src={cheeseImg} />
            </div>
            <div className="bk-box-single">
              <p dangerouslySetInnerHTML={{ __html: cheeseDesc }}></p>
              <div className="bk-comment">
                <div className="bk-comment-wrap">
                  <p dangerouslySetInnerHTML={{ __html: cheeseQuote }}></p>
                </div>
                <img alt="" src={smallYellowArrow} />
                <div className="box-1">
                  <h2>{cheeseQuotePerson}</h2>
                </div>
                <div className="box-2">
                  <h3>{solutionText1}</h3>
                  <h4>{solutionText2}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="bk-box ketchupContent">
            <img alt={ketchupTextAlt} src={ketchupText} />
            <div className="tiny-header">
              <span className="small">{smallText}</span>
              <span className="big">{ketchupHeader}</span>
            </div>
            <div className="bk-box-single">
              <img alt={ketchupImgAlt} className="spooky" src={ketchupImg} />
            </div>
            <div className="bk-box-single">
              <p dangerouslySetInnerHTML={{ __html: ketchupDesc }}></p>
              <div className="bk-comment">
                <div className="bk-comment-wrap">
                  <p dangerouslySetInnerHTML={{ __html: ketchupQuote }}></p>
                </div>
                <img alt="" src={smallRedArrow} />
                <div className="box-1">
                  <h2>{ketchupQuotePerson}</h2>
                </div>
                <div className="box-2">
                  <h3>{solutionText1}</h3>
                  <h4>{solutionText2}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="bk-box onionContent">
            <img alt={onionTextAlt} src={onionText} />
            <div className="tiny-header">
              <span className="small">{smallText}</span>
              <span className="big">{onionHeader}</span>
            </div>
            <div className="bk-box-single">
              <img alt={onionImgAlt} className="spooky" src={onionImg} />
            </div>
            <div className="bk-box-single">
              <p dangerouslySetInnerHTML={{ __html: onionDesc }}></p>
              <div className="bk-comment">
                <div className="bk-comment-wrap">
                  <p dangerouslySetInnerHTML={{ __html: onionQuote }}></p>
                </div>
                <img alt="" src={smallGreenArrow} />
                <div className="box-1">
                  <h2>{onionQuotePerson}</h2>
                </div>
                <div className="box-2">
                  <h3>{solutionText1}</h3>
                  <h4>{solutionText2}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="bk-box tomatoContent">
            <img alt={tomatoTextAlt} src={tomatoText} />
            <div className="tiny-header">
              <span className="small">{smallText}</span>
              <span className="big">{tomatoHeader}</span>
            </div>
            <div className="bk-box-single">
              <img alt={tomatoImgAlt} className="spooky" src={tomatoImg} />
            </div>
            <div className="bk-box-single">
              <p dangerouslySetInnerHTML={{ __html: tomatoDesc }}></p>
              <div className="bk-comment">
                <div className="bk-comment-wrap">
                  <p dangerouslySetInnerHTML={{ __html: tomatoQuote }}></p>
                </div>
                <img alt="" src={smallRedArrow} />
                <div className="box-1">
                  <h2>{tomatoQuotePerson}</h2>
                </div>
                <div className="box-2">
                  <h3>{solutionText1}</h3>
                  <h4>{solutionText2}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="bk-box baconContent">
            <img alt={baconTextAlt} src={baconText} />
            <div className="tiny-header">
              <span className="small">{smallText}</span>
              <span className="big">{baconHeader}</span>
            </div>
            <div className="bk-box-single">
              <img alt={baconImgAlt} className="spooky" src={baconImg} />
            </div>
            <div className="bk-box-single">
              <p dangerouslySetInnerHTML={{ __html: baconDesc }}></p>
              <div className="bk-comment">
                <div className="bk-comment-wrap">
                  <p dangerouslySetInnerHTML={{ __html: baconQuote }}></p>
                </div>
                <img alt="" src={smallYellowArrow} />
                <div className="box-1">
                  <h2>{baconQuotePerson}</h2>
                </div>
                <div className="box-2">
                  <h3>{solutionText1}</h3>
                  <h4>{solutionText2}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="bk-footer"></div>
      </div>
    </LayoutCustom>
  )
}

export const query = graphql`
  query HalloweenPage($locale: String) {
    ...LayoutFragment
    ...HalloweenPageFragment
    ...TranslationsFragment
  }
`
