import React, { useEffect, useState } from "react"
import "../scss/layout.global.scss"

import CookieConsent from "./CookieConsent/CookieConsent"
import { isGTmetrix, isLighthouse } from "../utils/env"
import { isCookieAccepted } from "../utils/cookie"

const LocaleContext = React.createContext()
export { LocaleContext }

export default function LayoutCustom({ i18n, children, langCode, pagePathsByLangCode }) {
  const [isWindowReady, setIsWindowReady] = useState(null)

  useEffect(() => {
    setIsWindowReady(true)
  }, [])

  return (
    <LocaleContext.Provider value={i18n}>
      <main>
{/*         {!isLighthouse() && !isGTmetrix() && !isCookieAccepted() && isWindowReady && (
          <CookieConsent
            currentMarket={i18n.header.locales[0].langCode}
            langCode={langCode}
            pagePathsByLangCode={pagePathsByLangCode}
          />)} */}
        {children}
      </main>
    </LocaleContext.Provider>
  )
}
